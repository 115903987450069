import { Fragment, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as constants from "../../../helpers/constant";
import { getMinMaxFromCurrentYear } from "../../../helpers/data_management";
import { MajorSpecialisation } from "../../profile/SpecialisationDropdown/MajorSpecialisation";
import {
  CheckIdleIcon,
  CheckLabel,
  CheckedIcon,
} from "../SignUpProfileDialog/styles";
import {
  CustomInput,
  EDUCATION_DESCRIPTION_MAX_LENGTH,
  MultilineTextarea,
  WORK_EXPERIENCE_DESCRIPTION_MAX_LENGTH,
} from "./ProfilePreviewDialog";
import {
  AutoCompleteStyled,
  BlackButton,
  CardHeaderContainer,
  CardTitle,
  CheckboxStyled,
  FormAction,
  FormControlLabelStyled,
  FormInput,
  FormLabel,
  FormWrapper,
  InputErrorText,
  InputGroup,
  SectionCardWrapper,
  TextButton,
  TextFieldStyled,
  TrashButtonContainer,
  TrashIcon,
  TrashIconButtonStyled,
} from "./styles";
import {
  Certification,
  EditCardProps,
  Education,
  FormField,
  Language,
  WorkExperience,
} from "./types";

export function EditCard<TData>(props: EditCardProps<TData>) {
  const {
    type,
    data,
    canDelete,
    onDelete,
    onCloseEditMode,
    updateResumeSection,
    saveEditResumeSection,
  } = props;
  const [formFields, setFormFields] = useState<FormField[]>([]);

  // Retrieve industry options
  const industryList = useSelector((state: any) => state.companies.industries);
  // Retrieve specialization options
  const specialisationList = constants.specialisations;

  // Needs to change the object title to label
  // This is MUI autocomplete requirements for options
  const specialisations = specialisationList.map((row: any, index: number) => {
    return { label: row.specialisation, id: index };
  });
  const industries = industryList.map((row: any) => {
    return { label: row.title, id: row.id };
  });

  useLayoutEffect(() => {
    if (type === "workExperiencesStructured") {
      setFormFields([
        {
          name: "Job Title",
          required: true,
          type: "input",
          defaultValue: (data as WorkExperience)?.jobTitle,
          field: "jobTitle",
          errorMessage: "Job title is required",
          placeholder: "Web Developer",
        },
        {
          name: "Company Name",
          required: true,
          type: "input",
          defaultValue: (data as WorkExperience)?.companyName,
          field: "companyName",
          errorMessage: "Company name is required",
          placeholder: "Nintendo",
        },
        {
          name: "Specialisation",
          type: "select",
          options: specialisations,
          defaultValue: (data as WorkExperience)?.specialisation,
          field: "specialisation",
          placeholder: "Please Select",
        },
        {
          name: "Industry",
          type: "select",
          options: industries,
          defaultValue: (data as WorkExperience)?.industry,
          field: "industry",
          placeholder: "Please Select",
        },
        {
          name: "I am currently working in this role",
          type: "check",
          defaultValue: (data as WorkExperience)?.currentWork,
          field: "currentWork",
          checked: (data as WorkExperience)?.currentWork,
        },
        {
          name: "Start Date",
          required: true,
          type: "yearMonth",
          yearOptions: getMinMaxFromCurrentYear(),
          defaultYear: (data as WorkExperience)?.startDateYear,
          monthOptions: constants.months,
          defaultMonth: (data as WorkExperience)?.startDateMonth,
          yearField: "startDateYear",
          monthField: "startDateMonth",
        },
        {
          name: "End Date",
          required: true,
          type: "yearMonth",
          yearOptions: getMinMaxFromCurrentYear(),
          defaultYear: (data as WorkExperience)?.endDateYear,
          monthOptions: constants.months,
          defaultMonth: (data as WorkExperience)?.endDateMonth,
          yearField: "endDateYear",
          monthField: "endDateMonth",
        },
        {
          name: "Description",
          type: "longInput",
          defaultValue: (data as WorkExperience)?.jobDescription,
          field: "jobDescription",
          maxLength: WORK_EXPERIENCE_DESCRIPTION_MAX_LENGTH,
          placeholder:
            "Describe your position in details, tasks completed etc.",
        },
      ]);
    }

    if (type === "educationsStructured") {
      setFormFields([
        {
          name: "Education Level",
          required: true,
          type: "select",
          defaultValue: (data as Education)?.educationLevel,
          field: "educationLevel",
          options: constants.educationLevels,
          errorMessage: "Education Level is required",
          placeholder: "Please Select",
        },
        {
          name: "Field of Study",
          required: true,
          type: "select",
          defaultValue: (data as Education)?.educationName,
          field: "educationName",
          options: constants.fields,
          errorMessage: "Field of Study is required",
          placeholder: "Please Select",
        },
        {
          name: "Education Institution",
          required: true,
          type: "select",
          defaultValue: (data as Education)?.institution,
          field: "institution",
          options: constants.institutions,
          errorMessage: "Education Institution is required",
          placeholder: "Please Select",
        },
        {
          name: "Graduation Year",
          required: true,
          type: "select",
          defaultValue: (data as Education)?.graduationYear,
          field: "graduationYear",
          options: getMinMaxFromCurrentYear(),
          errorMessage: "Graduation Year is required",
          placeholder: "Year",
        },
        {
          name: "Description",
          type: "longInput",
          defaultValue: (data as Education)?.description,
          field: "description",
          maxLength: EDUCATION_DESCRIPTION_MAX_LENGTH,
          placeholder: "Describe your education achievement, GPA, honors, etc.",
        },
      ]);
    }

    if (type === "languages") {
      setFormFields([
        {
          name: "Language",
          type: "select",
          defaultValue: (data as Language)?.language,
          field: "language",
          options: constants.languages,
          placeholder: "Please Select",
        },
        {
          name: "Proficiency",
          required: true,
          type: "select",
          defaultValue: (data as Language)?.proficiency,
          field: "proficiency",
          options: constants.proficiency,
          errorMessage: "Proficiency is required",
          placeholder: "Please Select",
        },
      ]);
    }

    if (type === "certifications") {
      setFormFields([
        {
          name: "Courses/ Certificate Name",
          required: true,
          type: "input",
          defaultValue: (data as Certification)?.name,
          field: "certificationName",
          errorMessage: "Courses/ Certificate name is required",
          placeholder: "Type here",
        },
        {
          name: "Issuing Organisation",
          required: true,
          type: "input",
          defaultValue: (data as Certification)?.organisation,
          field: "organisation",
          errorMessage: "Issuing organisation is required",
          placeholder: "Type here",
        },
        {
          name: "Year of Completion",
          required: true,
          type: "select",
          defaultValue: (data as Certification)?.year,
          field: "year",
          options: getMinMaxFromCurrentYear(),
          errorMessage: "Year of Completion is required",
          placeholder: "Year",
        },
        {
          name: "Credential URL",
          type: "input",
          defaultValue: (data as Certification)?.url,
          field: "url",
          placeholder: "Type here",
        },
      ]);
    }
  }, [data]);

  const formTitle =
    type === "workExperiencesStructured"
      ? "Edit Work Experience"
      : type === "certifications"
      ? "Edit Certificates & Training"
      : type === "educationsStructured"
      ? "Edit Education"
      : "Edit Language";

  function saveDisabled() {
    return !formFields.every((field) => {
      if (
        field.required &&
        (field.type === "input" || field.type === "select") &&
        !field?.defaultValue?.toString()?.length
      ) {
        return false;
      } else if (
        field.type === "longInput" &&
        field.defaultValue?.length > field?.maxLength
      ) {
        return false;
      }
      return true;
    });
  }

  return (
    <SectionCardWrapper editMode>
      <CardHeaderContainer>
        <CardTitle>{formTitle}</CardTitle>
      </CardHeaderContainer>
      <FormWrapper>
        {formFields?.map((field, index) => {
          switch (field.type) {
            case "input":
              return (
                <FormInput key={index}>
                  <FormLabel>
                    {field.name}
                    {field?.required && "*"}
                  </FormLabel>
                  <CustomInput
                    defaultValue={field?.defaultValue}
                    placeholder={field?.placeholder}
                    error={field?.required && !field?.defaultValue?.length}
                    helperText={field?.errorMessage}
                    onUpdate={(input) =>
                      updateResumeSection(field.field, input)
                    }
                  />
                </FormInput>
              );
            case "longInput":
              return (
                <FormInput style={{ flexBasis: "100%" }} key={index}>
                  <FormLabel>{field.name}</FormLabel>
                  <MultilineTextarea
                    defaultValue={field?.defaultValue}
                    placeholder={field?.placeholder}
                    maxLength={field?.maxLength}
                    onUpdate={(input) =>
                      updateResumeSection(field.field, input)
                    }
                    error={field?.defaultValue?.length > field?.maxLength}
                  />
                </FormInput>
              );
            case "select":
              if (field.name === "Specialisation") {
                return (
                  <FormInput>
                    <MajorSpecialisation
                      roundedRectangle
                      value={field?.defaultValue}
                      specialisationHandler={(specialisation: any) => {
                        updateResumeSection(field.field, specialisation);
                      }}
                    />
                  </FormInput>
                );
              } else {
                return (
                  <FormInput key={index}>
                    <FormLabel>
                      {field.name}
                      {field?.required && "*"}
                    </FormLabel>
                    <div>
                      <AutoCompleteStyled
                        value={field?.defaultValue}
                        forcePopupIcon={true}
                        freeSolo={field?.freeSolo}
                        options={field?.options}
                        onChange={(event, value: any) => {
                          updateResumeSection(field.field, value);
                        }}
                        disableClearable
                        renderInput={(params) => (
                          <TextFieldStyled
                            {...params}
                            label={""}
                            placeholder={field?.placeholder}
                          />
                        )}
                        error={
                          field?.required &&
                          !field?.defaultValue?.toString()?.length
                        }
                      />
                      {field?.required &&
                        !field?.defaultValue?.toString()?.length && (
                          <InputErrorText>{field?.errorMessage}</InputErrorText>
                        )}
                    </div>
                  </FormInput>
                );
              }
            case "check":
              return (
                <FormControlLabelStyled
                  key={index}
                  control={
                    <CheckboxStyled
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckIdleIcon />}
                      onChange={(e) => {
                        updateResumeSection(field.field, e.target.checked);
                      }}
                      checked={field?.checked}
                    />
                  }
                  label={<CheckLabel>{field.name}</CheckLabel>}
                />
              );
            case "yearMonth":
              return (
                <Fragment>
                  {field.name === "End Date" &&
                  (data as WorkExperience)?.currentWork ? null : (
                    <FormInput key={index}>
                      <FormLabel>
                        {field.name}
                        {(data as WorkExperience)?.currentWork
                          ? false
                          : field?.required && "*"}
                      </FormLabel>
                      <InputGroup>
                        <AutoCompleteStyled
                          value={field?.defaultYear}
                          options={field?.yearOptions}
                          renderInput={(params) => (
                            <TextFieldStyled
                              {...params}
                              label={""}
                              placeholder="Year"
                            />
                          )}
                          onChange={(event, value: any) => {
                            updateResumeSection(field?.yearField, value);
                          }}
                          disableClearable
                          error={field?.required && !field?.defaultYear?.length}
                        />
                        <AutoCompleteStyled
                          value={field?.defaultMonth}
                          options={field?.monthOptions}
                          renderInput={(params) => (
                            <TextFieldStyled
                              {...params}
                              label={""}
                              placeholder="Month"
                            />
                          )}
                          onChange={(event, value: any) => {
                            updateResumeSection(
                              field?.monthField,
                              value?.label
                            );
                          }}
                          disableClearable
                          error={
                            field?.required && !field?.defaultMonth?.length
                          }
                        />
                      </InputGroup>
                      {field?.required &&
                        (!field?.defaultMonth?.length ||
                          !field?.defaultYear?.length) && (
                          <InputErrorText>
                            {field?.name} is required
                          </InputErrorText>
                        )}
                    </FormInput>
                  )}
                </Fragment>
              );
          }
        })}

        {/* NOTE: Feild that can be deleted (e.g, Language) will render delete button */}
        {canDelete && (
          <TrashButtonContainer>
            <TrashIconButtonStyled onClick={onDelete}>
              <TrashIcon />
            </TrashIconButtonStyled>
          </TrashButtonContainer>
        )}

        <FormAction>
          <TextButton variant="text" onClick={onCloseEditMode}>
            Cancel
          </TextButton>
          <BlackButton
            big
            onClick={saveEditResumeSection}
            disabled={saveDisabled()}
          >
            Save
          </BlackButton>
        </FormAction>
      </FormWrapper>
    </SectionCardWrapper>
  );
}
